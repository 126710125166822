import http from "./http";
import { BASE_SYSTEM_ENUM } from "@/utils/base";

// 获取用户菜单
const baseUserOwnTree = () => http.get(`${BASE_SYSTEM_ENUM.API_PRODUCT}/system/menu/getRouters`);
// 获取用户基本信息
const baseAutorizeMe = (params: any = {}) => http.get(`/${BASE_SYSTEM_ENUM.API_BASE}/authorize/me`, {params});
// 获取系统基本信息
const baseSystemConfig = (params: any = {}) => http.get(`/${BASE_SYSTEM_ENUM.API_BASE}/system/config/front`, {params});
// 获取通知数据
const baseNotices = (params: any = {}) => http.get(`${BASE_SYSTEM_ENUM.API_PRODUCT}/system/notifications/_query`, {params});
// 清除通知
const baseClearNotices = (params: any = {}) => http.post(`${BASE_SYSTEM_ENUM.API_PRODUCT}/system/notifications/_read`, params);
// 改变通知状态
const baseChangeNoticeReadState = (params: any = {}) => http.get(`${BASE_SYSTEM_ENUM.API_PRODUCT}/system/notifications/${params.id}/read`);
// 退出登录
const baseLogout = () => http.delete(`${BASE_SYSTEM_ENUM.API_PRODUCT}/auth/logout`);

// 获取权限
const baseUserProfile = () => http.get(`${BASE_SYSTEM_ENUM.API_PRODUCT}/system/user/profile`);
// 获取权限列表
const baseTreeSelect = () => http.get(`${BASE_SYSTEM_ENUM.API_PRODUCT}/system/menu/v2/treeselect`);


export default {
  baseUserOwnTree,
  baseAutorizeMe,
  baseSystemConfig,
  baseNotices,
  baseClearNotices,
  baseChangeNoticeReadState,
  baseLogout,
  baseUserProfile,
  baseTreeSelect
};
