/**
 * 左右布局的展示统计数值。
 */
import { useState } from 'react';
import { handleHttp } from '@/components/Menu/ProCard/utils/valueConfig';
import { DEFAULT_DATA } from '@/config';
import './index.less';

export interface DescriptionProps {
  label?: string;
  value?: string;
  valueStyle?: object;
  valueConfig?: any;
  storeData?: any;
}

const DescriptionClassPrefix = 'iot-component-description';

const Description = (props: DescriptionProps) => {
  const { label, value = DEFAULT_DATA, valueStyle = {}, valueConfig = null, storeData = {} } = props;
  const [result, setResult] = useState(value);
  if (valueConfig) {
    if (valueConfig.type === 'http' && result === DEFAULT_DATA) {
      handleHttp(valueConfig, (resultIn: any) => {
        setResult(resultIn);
      });
    } else if (
      valueConfig.type === 'store' &&
      !['', DEFAULT_DATA, undefined].includes(storeData[valueConfig.dataKey]) &&
      result === DEFAULT_DATA
    ) {
      setResult(storeData[valueConfig.dataKey]);
    }
  }
  return (
    <div className={`${DescriptionClassPrefix}`}>
      <div className={`${DescriptionClassPrefix}-label`}>{label}</div>
      <div className={`${DescriptionClassPrefix}-content`} style={valueStyle}>
        {result}
      </div>
    </div>
  );
};

export default Description;
