import Cookies from "js-cookie";
import { authCookieConfig, PROCESS_ENV_NODE } from "./base";

interface AuthorizationTypes {
  default: string;
  local: string;
  dev: string;
  test: string;
}

interface ServiceValTypes{
  Authorization: AuthorizationTypes;
}

const cookiesConfig: ServiceValTypes = {
  Authorization: {
    default: 'Authorization',
    local: 'local_Authorization',
    dev: 'dev_Authorization',
    test: 'test_Authorization'
  }
}

const judgeServiceVal = (key: "Authorization") => {
  let serviceValStr = '';
  if(Object.keys(cookiesConfig).indexOf(key) !== -1) {
    serviceValStr = cookiesConfig[key].default;

    if(PROCESS_ENV_NODE === 'development') {
      // 本地环境
      serviceValStr = cookiesConfig[key].local;
    } else {
      if(window.location.origin.indexOf('//dev') !== -1) {
        // 开发环境
        serviceValStr = cookiesConfig[key].dev;
      }

      if(window.location.origin.indexOf('//test') !== -1) {
        // 测试
        serviceValStr = cookiesConfig[key].test;
      }
    }
  }
  return serviceValStr;
}

/**
 * 获取当前环境的用户cookie key
 * Authorization: 生产    
 * dev_Authorization: 开发   
 * test_Authorization: 测试
 * @returns 当前环境对应的用户token的cookie key
 */
export const getServiceVal = (key: "Authorization") => {
  if(!key) {
    console.warn('获取cookie的key为不能空');
    return '';
  }

  if(Object.keys(cookiesConfig).indexOf(key) === -1) {
    console.warn(`获取cookie的key(${key})不在配置列表中`);
    return '';
  }
  
  return Cookies.get(judgeServiceVal(key) || cookiesConfig[key].default);
}

/**
 * 设置cookie
 * @param key cookie的key
 * @param value cookie的值
 * @param options 更多配置（包括path、domain、有效期）
 * @returns 
 */
export const setServiceVal = (key: "Authorization", value: any, options: any) => {
  if(!key) {
    console.warn('获取cookie的key为不能空');
    return '';
  }

  Cookies.set(judgeServiceVal(key), value, options);
}

/**
 * 移除cookie
 * @param key cookie的key
 * @param options 更多配置（包括path、domain）
 * @returns 
 */
export const removeServiceVal = (key: "Authorization", options: any = null) => {
  if(!key) {
    console.warn('获取cookie的key为不能空');
    return '';
  }

  Cookies.remove(judgeServiceVal(key), options || {path: authCookieConfig.path, domain: authCookieConfig.domain});
}