import { initGlobalState } from "qiankun";

const initState = {
  breadcrumbItems: [], // 主应用面包屑数据
  hashPath: '', // hash路径
  cardBtnClick: {},
  cardUpdate: {}, // { code: 'open/app', timestamp: +new Date(), }
};


const actions = initGlobalState(initState);

// actions.onGlobalStateChange((state, prev) => {
//   // 监听全局状态的变化
//   console.log('主应用：变更前', prev);
//   console.log('主应用：变更后', state);
// });

export default actions;
