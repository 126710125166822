const CardDataMap: any = {

  "/ClientManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  "/ContractManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  "/OSProductManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  "/HardwareProductManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  "/LicenseListManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  "/LicenseManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  "/EquipmentRecord": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  "/EquipmentManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  "/OTAManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  "/OTAUpdate": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  "/OrganizationManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  "/RoleMaintenance": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  "/UserMaintenance": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  "/MenuManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  "/DictManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  "/LogManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  "/operLog": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  "/loginLog": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  "/FaultManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  "/ApplicationManagement": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  "/atomic-center/eventManager": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  "product/productPortal22": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),

};

export default CardDataMap;
