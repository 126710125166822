import { BASE_SYSTEM_ENUM, loginUrl } from "@/utils/base";
import actions from "@/stores/globalstate";
import store from "@/stores";
let currentHashPath = "";

const origin = location.origin;
const getActiveRule = (hash: string) => (location: any) =>
  location.hash.startsWith(hash);
const MICRO_APPS: any = {
  "product-platform": {
    entry: 'http://localhost:3001',
    activeRule: `/product-platform/`,
    mode: 'history', // 'hash'
  },
  // "product-platform": {
  //   entry: 'http://localhost:3001',
  //   activeRule: `/product-platform/`,
  //   mode: 'history', // 'hash'
  // },
  "product-portal": {
    entry: "http://localhost:9528",
    activeRule: `/product-portal/`,
    mode: "hash"
  },
  "atomic-center": {
    entry: 'http://dev.iot.kaihong.com',
    activeRule: `/atomic-center/`,
    mode: 'history', // 'history'
  },
  // "atomic-center": {
  //   entry: 'http://dev.iot.kaihong.com',
  //   activeRule: `/atomic-center/`,
  //   mode: 'history', // 'history'
  // },
  // "atomic-center": {
  //   entry: 'http://dev.iot.kaihong.com',
  //   activeRule: `/visual/atomic-center/`,
  //   mode: 'history', // 'hash'
  // },
  // "dp-center": {
  //   entry: 'http://dev.iot.kaihong.com',
  //   activeRule: `/dms/dp-center/`,
  //   mode: 'history', // 'hash'
  // },
  // "khlinks-web": {
  //   entry: 'http://localhost:9000',
  //   activeRule: getActiveRule('#/khlinks-web'),
  //   mode: 'hash', // 'hash'
  // },
};

/**
 * 子应用名称列表
 * "khlinks-web": 超级设备管理平台
 * "atomic-center"：原子化服务平台
 * "dms"：数据管理服务
 */
export const subAppNames = Object.keys(MICRO_APPS);

// 特殊的子应用-超级终端管理平台（需要页数处理基座应用菜单跳转）
export const specialAppName = "khlinks-web";

// const normalAppNames = subAppNames.filter((name) => name !== specialAppName);

let env = "prod";
if (origin.includes("localhost")) {
  env = "dev";
}

const getEntry = (subAppName: string) => {
  if (subAppName === specialAppName) {
    return env === "prod" ? origin : MICRO_APPS[subAppName]["entry"];
  }
  console.log(
    "123213213123",
    env === "prod"
      ? origin +
          "/sub-app" +
          BASE_SYSTEM_ENUM.APP_NAME +
          MICRO_APPS[subAppName]["activeRule"]
      : MICRO_APPS[subAppName]["entry"] + MICRO_APPS[subAppName]["activeRule"]
  );

  return env === "prod"
    ? origin + "/sub-app" + MICRO_APPS[subAppName]["activeRule"]
    : MICRO_APPS[subAppName]["entry"] +
        "/sub-app" +
        MICRO_APPS[subAppName]["activeRule"];
};

// 子应用注册初始化
export const getMicroApps = () => {
  const result = subAppNames.map((subAppName) => {
    return {
      name: subAppName,
      entry: getEntry(subAppName),
      container: "#main-container",
      activeRule: `/${BASE_SYSTEM_ENUM.APP_NAME}${MICRO_APPS[subAppName]["activeRule"]}`,
      loader: (loading: boolean) => {
        console.log(`${subAppName} 子应用loading`, +new Date(), loading);

        store.dispatch({
          type: "BASE_MAIN_ROOT_ISLOADING",
          data: loading,
        });
      },

      props: {
        routerBase: `/${BASE_SYSTEM_ENUM.APP_NAME}${MICRO_APPS[subAppName]["activeRule"]}`,
        utils: {
          login () {
            // 跳转页面登录
            console.log('子应用调用了跳转登录页面方法');
            const url = `${loginUrl}?jump_url=${encodeURIComponent(window.location.href)}`;
            console.log('utils login url', url);
            
            window.open(url, '_self', 'toolbar=yes, menubar=yes, scrollbars=yes, resizable=yes, location=yes, status=yes');
          }
        }
      },
    };
  });

  return result;
};

/**
 * 根据传入的url获取子应用完整路径
 * @param url
 */
export const getSubAppUrl = (url: string) => {
  let realAppUrl = BASE_SYSTEM_ENUM.APP_NAME;
  // subAppNames
  console.log(1111);

  if (
    subAppNames.some(
      (app) =>
        MICRO_APPS[app]["mode"] === "history" &&
        (url.startsWith(MICRO_APPS[app]["activeRule"]) ||
          url.startsWith(MICRO_APPS[app]["rule"]))
    )
  ) {
    // 为history模式的子应用
    realAppUrl += url;
  } else {
    // hash模式
    // MICRO_APPS[app]['mode']
    const result: any = subAppNames.filter(
      (app) =>
        MICRO_APPS[app]["mode"] === "hash" &&
        (url.startsWith(MICRO_APPS[app]["activeRule"]) ||
          url.startsWith(MICRO_APPS[app]["rule"]))
    );
    if (result.length) {
      if (location.hash.includes("/kh_")) {
        currentHashPath = url;
        actions.setGlobalState({ hashPath: url });
        return "";
      } else {
        realAppUrl += url; // `${result[0]['activeRule']}${url}`;
      }
      // realAppUrl += '#/khlinks' + url; // `${result[0]['activeRule']}${url}`;
      // actions.setGlobalState({ hashPath: url });
    } else {
      console.warn(`未知的应用菜单：${url}`);
    }
  }
  console.log("realAppUrl", realAppUrl);

  return realAppUrl;
};

window.addEventListener("hashchange", () => {
  /*
  slice(start,end):切片，返回新数组
      start——必需。规定从何处开始选取。
      end——可选。规定从何处结束选取。该参数是数组片断结束处的数组下标。如果没有指定该参数，那么切分的数组包含从 start 到数组结束的所有元素。
  * */
  const currentHash = location.hash.replace(`#/${specialAppName}`, "");
  if (currentHash !== currentHashPath) {
    currentHashPath = currentHash;
    actions.setGlobalState({ hashPath: currentHash });
  }
});
