/*
 * @Author: lenghao lenghao@kaihong.com
 * @Date: 2022-12-06 14:14:21
 * @LastEditors: OSS-LIRUNBING-0\dell 18312288722@139.com
 * @LastEditTime: 2023-02-16 13:48:47
 * @FilePath: \twin-view\src\api\http.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import type { AxiosInstance } from "axios";
import { loginUrl } from "@/utils/base";
import { getServiceVal } from "@/utils/cookies";

type serviceConfigType = {
  baseURL: string;
  timeout: number;
  withCredentials: boolean;
  headers: any;
};

// 未登录状态码
const NOT_LOGIN_STATES: string[] = ["401"];

/**
 * 判断是否登录
 * @param status 状态
 * @returns
 */
const hasLogin = (status: number) => {
  if (
    NOT_LOGIN_STATES.indexOf(String(status)) > -1 &&
    !location.href.includes(loginUrl)
  ) {
    // 未登录
    window.open(
      `${loginUrl}?jump_url=${encodeURIComponent(window.location.href)}`,
      "_self",
      "toolbar=yes, menubar=yes, scrollbars=yes, resizable=yes, location=yes, status=yes"
    );
    return false;
  } else {
    return true;
  }
};

// 请求配置处理
const serviceConfig: serviceConfigType = {
  baseURL: "",
  timeout: 30 * 1000, // 超时时间
  withCredentials: true, // 表示跨域请求时是否需要使用凭证
  headers: {}, // 自定义请求头
};

// 给请求增加自定义请求头，将token放到请求头
const XAccessToken =
  localStorage.getItem("Authorization") || getServiceVal("Authorization") || ""; // localStorage.getItem("token") || localStorage.getItem("Authorization");
if (XAccessToken) {
  serviceConfig.headers["Authorization"] = XAccessToken;
}

const service: AxiosInstance = axios.create(serviceConfig);

service.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => config
);

service.interceptors.response.use(
  (res: AxiosResponse) => {
    const { data } = res;
    const { status, code } = data;

    if (!hasLogin(status || code)) {
      return;
    }

    return data;
  },
  (err) => {
    const {
      response: { status },
    } = err;
    if (!hasLogin(status)) {
      return;
    }
    return Promise.reject(err);
  }
);

const http = {
  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return service.get(url, config);
  },

  post<T = any>(
    url: string,
    data?: object,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return service.post(url, data, config);
  },

  put<T = any>(
    url: string,
    data?: object,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return service.put(url, data, config);
  },

  delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return service.delete(url, config);
  },
};

export { service };

export default http;
