// import BaseService from '@/utils/BaseService';
import { service as request } from "@/api/http";
// import SystemConst from '@/utils/const';

class Service {
  // api request
  public requestCommon = (url: string, params: any) =>
    request(`${url}`, params);

  public requestCommonModel = ({ url = "", params = {} }) =>
    request(`${url}`, params);

  public initMenu = () =>
    request('/api/card/getCardByUserId');
}

export default Service;
