export interface CustomProps {
  render?: any;
}

const Custom = (props: CustomProps) => {
  const { render = "() => <div>custom default</div>" } = props;
  return render;
};

export default Custom;
