/*
 * @Author: lenghao lenghao@kaihong.com
 * @Date: 2022-12-05 20:09:30
 * @LastEditors: lenghao lenghao@kaihong.com
 * @LastEditTime: 2022-12-09 17:36:44
 * @FilePath: \dtp-ui\src\stores\index.ts
 * @Description: 状态管理统一出口文件
 */

import { legacy_createStore as createStore, combineReducers } from "redux";
import { base } from "./base";


// 全局你可以创建多个reducer
const rootReducers = combineReducers({
  base
})
// 全局就管理一个store
const store = createStore(rootReducers);

export default store;