import { cloneDeep } from "lodash-es";
interface ReduxState {
  menus: any;
  leftMenus: any;
  autorize: any;
  system: any;
  notifications: any;
  BASE_MAIN_ROOT_ISLOADING: boolean;
}

interface Action {
  type: string;
  data: any;
}

const defaultAutorize = {
  attributes: {},
  dimensions: [],
  permissions: [],
  user: {}
};

const initData = {
  menus: [], // 顶部菜单导航
  leftMenus: [], // 左侧菜单导航
  autorize: cloneDeep(defaultAutorize), // 用户基本信息，包括权限、用户信息
  system: {}, // 系统基本信息
  notifications: {}, // 消息
  BASE_MAIN_ROOT_ISLOADING: false, // 基座加载子应用loading
};

const BASE_SET_MENU = 'BASE_SET_MENU'; // 设置菜单常量标识
const BASE_SET_LEFT_MENU = 'BASE_SET_LEFT_MENU'; // 设置左侧菜单标识
const BASE_SET_AUTORIZE = 'BASE_SET_AUTORIZE'; // 设置用户基本信息，包括权限、用户信息
const BASE_SET_SYSTEM = 'BASE_SET_SYSTEM'; // 设置系统基本信息
const BASE_SET_NOTIFICATIONS = 'BASE_SET_NOTIFICATIONS'; // 设置消息数据
const BASE_MAIN_ROOT_ISLOADING = 'BASE_MAIN_ROOT_ISLOADING'; // 设置子应用加载状态

const base = (state: ReduxState = initData, action: Action) => {
  const { type, data } = action;
  let resultAutorize;

  switch (type) {
    case BASE_SET_MENU:
      // 设置顶部菜单
      return {
        ...state,
        menus: data
      };
    case BASE_SET_LEFT_MENU:
      // 设置左侧菜单
      return {
        ...state,
        leftMenus: data
      };
    case BASE_SET_AUTORIZE:
      // 设置用户基本信息
      resultAutorize = cloneDeep(state.autorize);
      if(!data) {
        resultAutorize = cloneDeep(defaultAutorize);
      } else {
        resultAutorize = {
          ...resultAutorize,
          ...data
        }
      }
      return {
        ...state,
        autorize: resultAutorize
      }
    case BASE_SET_SYSTEM:
      // 设置系统基本信息
      return {
        ...state,
        system: data
      }
    case BASE_SET_NOTIFICATIONS:
      // 设置消息数据
      return {
        ...state,
        notifications: data
      }
    case BASE_MAIN_ROOT_ISLOADING:
      
      // 设置子应用加载状态
      return {
        ...state,
        BASE_MAIN_ROOT_ISLOADING: data
      }

    default:
      return state;
  }
};

export { 
  BASE_SET_MENU,
  BASE_SET_LEFT_MENU,
  BASE_SET_AUTORIZE,
  BASE_SET_SYSTEM,
  BASE_SET_NOTIFICATIONS,
  BASE_MAIN_ROOT_ISLOADING,
  base 
};
